import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import Select from "react-select";

function Formpr(props) {
  function refreshPage() {
    window.location.reload(false);
  }

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [notes, setcil] = useState([]);
  const [plans, setplans] = useState([]);
  const [client, setclient] = useState("");
  const [plani, setplano] = useState("");
  const [cost, setcost] = useState("");
  const [selling, setselling] = useState(0);
  const [message, setMessage] = useState("");

  const validate = () => {
    return plani !== "" && selling !== "" && client !== "";
  };

  useEffect(() => {
    let isMounted = true;

    const getresellers = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/dealers/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setcil(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    const getplans = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/plans/internet`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setplans(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getresellers();
    getplans();

    return () => {
      isMounted = false;
    };
  }, [authTokens.access, user.username]);

  const options = notes.map((item) => ({
    label: item.fullname_en,
    value: item.dealer_id,
  }));

  const plano = plans.map((item) => ({
    label: item.plan_name,
    value: item.internet_plan_id,
    cost: item.cost,
  }));

  let handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await Axios.post(
        `${API_URL}/radius/plans/resellers/`,
        {
          Action: "Post",
          plan_id: plani,
          child_id: client,
          selling_price: selling,
          userdealer: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        setMessage(`${response.data}`);
        setTimeout(() => {
          setMessage("");
          refreshPage();
        }, 3000);
      }
    } catch (error) {
      console.log(error);
      setMessage(
        `Error adding plan. Response: ${
          error.response ? error.response.data.message : "Unknown error"
        }`
      );
      setTimeout(() => {
        setMessage("");
      }, 2000);
    }
  };

  return (
    <div className="mod">
      <form onSubmit={handleSubmit}>
        <div className="name">
          <div className="form-group">
            <label>
              Reseller Name:
              <Select
                className="search-line"
                placeholder="Choose Client"
                options={options}
                onChange={(opt) => setclient(opt.value)}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Plan Name:
              <Select
                className="search-line"
                placeholder="Choose plan"
                options={plano}
                onChange={(opt) => {
                  setplano(opt.value);
                  setcost(opt.cost);
                }}
              />
            </label>
          </div>
        </div>
        <div className="name">
          <div className="form-group"></div>

          <div className="form-group">
            <label>
              Cost:
              <input
                type="text"
                className="form-control"
                value={cost}
                placeholder="Cost"
                disabled
              />
            </label>
          </div>
        </div>
        <div className="name">
          <div className="form-group">
            <label>
              Selling Price:
              <input
                type="number"
                className="form-control"
                value={selling}
                required
                placeholder="Selling price"
                onChange={(e) => setselling(e.target.value)}
              />
            </label>
          </div>
        </div>
        {message && (
          <div
            className={`message ${
              message.includes("Error") ? "error" : "success"
            }`}
          >
            {message}
          </div>
        )}

        <div className="fo">
          <button
            type="submit"
            className="btn btn-primary btn-md"
            disabled={!validate()}
          >
            Create
          </button>
        </div>
      </form>
    </div>
  );
}

export default Formpr;
