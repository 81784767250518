import React, { useState, useEffect, useContext, useRef } from "react";
import { API_URL } from "../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import Checkbox from "@mui/material/Checkbox";
import CustomizedActions from "./BulkActions";
import AuthContext from "../context/AuthContext";
import Formsa from "./editsub";
import Ping from "./ping";
import ConsumptionHistory from "./consumptionHistory";
import TransferDays from "./TransferDays";
import ExcelSVG from "../../ExcelSVG.svg";
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import ProgressBar from "./progress.js";
import "./Internet.css";

function SubInternet() {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkedRows, setCheckedRows] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // To capture search input
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null); // For delayed search filter

  const [showo, setShowo] = useState(false);
  const [password, setpassword] = useState(0);
  const handleshowo = () => setShowo(true);
  const handleedit = () => setShowo(false);

  const [showPing, setShowPing] = useState(false);
  const handleshowPing = () => setShowPing(true);
  const handleClosePing = () => setShowPing(false);

  const [showHistory, setShowHistory] = useState(false);
  const handleshowHistory = () => setShowHistory(true);
  const handleCloseHistory = () => setShowHistory(false);

  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(0);
  const handleUpdateAct = (id) => setSelectedSubscriptionId(id);

  const [showTransfer, setShowTransfer] = useState(false);
  const handleshowTransfer = () => setShowTransfer(true);
  const handleCloseTransfer = () => setShowTransfer(false);

  const [subscriptionDetails, setSubscriptionDetails] = useState({
    username: "",
    password: 0,
    subId: 0,
    intSubId: 0,
    intPlanId: 0,
    site: "",
    planName: "",
    fullName: "",
    expDate: "",
    payDate:"",
    consumption: "",
    connectivity: "",
    due: "",
    ip: "",
    notes: "",
    site_name: "",
    WAP: "",
    core: "",
    mac_address: "",
  });

  const updateSubscriptionDetails = (details) => {
    setSubscriptionDetails(details);
  };

  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows
  const [filters, setFilters] = useState({
    status: "",
    username: "",
    planName: "",
    fullName: "",
    due: "",
    expiration_date: "",
    consumption: "",
    connectivity: "",
    due: "",
    mac_address: "",
    subscription_secret: "",
    labels: "",
    uptime: "",
  });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [gridFilterModel, setGridFilterModel] = useState({
    items: []
  });

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(`${API_URL}/radius/subscriptions/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch data when the component mounts
    fetchData();

    // Set up a timer to fetch data every 5 seconds
    const intervalId = setInterval(fetchData, 5000);

    // Clean up the timer when the component unmounts
    return () => clearInterval(intervalId);
  }, [user.username]);

  const getStatusCellStyle = (params) => {
    let statusColor = "";
    switch (params.value) {
      case "offline":
        statusColor = "status-offline";
        break;
      case "online":
        statusColor = "status-online";
        break;
      case "inactive":
        statusColor = "status-inactive";
        break;
      case "expired":
        statusColor = "status-expired";
        break;
      case "hold":
        statusColor = "status-hold";
        break;
      default:
        break;
    }
    return statusColor;
  };

  const getPercentageCellValue = (params) => {
    if (params.row.plan_name.includes("STARTER")) {
      return params.row.monthly_percentage + "%";
    }
    return params.row.percentage + "%";
  };

  const columns = [
    {
      field: "checkbox",
      headerName: "",
      width: 30,
      renderCell: (params) => (
        <Checkbox
          className="subcheck"
          checked={checkedRows.includes(params.row.subscription_id)}
          onChange={(event) =>
            handleCheckboxChange(event, params.row.subscription_id)
          }
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 70,
      cellClassName: (params) => getStatusCellStyle(params), // Apply cell style based on status
    },
    { field: "username", headerName: "Username", width: 150 },
    {
      field: "fullname_en",
      headerName: "Full Name",
      flex: 2,
      minWidth: 250,
    },
    {
      field: "plan_name",
      headerName: "Plan Name",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        // Check if the plan_name includes "BASIC" to determine which percentage to use
        const percentage = params.row.plan_name.includes("STARTER")
          ? params.row.monthly_percentage
          : params.row.percentage;

        return (
          <ProgressBar
            percentage={percentage}
            planName={params.row.plan_name}
            max={params.row.daily_limit}
            download={params.row.pppoe_download}
            upload={params.row.pppoe_upload}
          />
        );
      },
    },
    { field: "ip_address", headerName: "IP Address", flex: 1, minWidth: 120 },
    {
      field: "subscription_secret",
      headerName: "secret",
      flex: 1,
      minWidth: 85,
    },
    {
      field: "expiration_date",
      headerName: "Expiry",
      flex: 1,
      minWidth: 120,
      valueFormatter: (params) => {
        const dateString = params.value;
        // Parse the ISO 8601 format string
        const date = new Date(dateString);

        // Extract day, month, and year
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear();

        // Format the date as dd-mm-yyyy
        return `${day}-${month}-${year}`;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <div>
          <div
            onClick={() => handleUpdateAct(params.row.subscription_id)}
          ></div>
          <button
            onClick={() => {
              const details = {
                username: params.row.username,
                password: params.row.password,
                subId: params.row.subscription_id,
                intSubId: params.row.internet_subscription_id,
                intPlanId: params.row.internet_plan_id,
                site: params.row.site,
                planName: params.row.plan_name,
                fullName: params.row.fullname_en,
                expDate: params.row.expiration_date,
                payDate: params.row.payment_date,
                consumption: params.row.consumption_option,
                connectivity: params.row.connectivity,
                due: params.row.due,
                ip: params.row.ip_address,
                notes: params.row.notes,
                site_name: params.row.site_name,
                WAP: params.row.name,
                mac_address: params.row.mac_address,
                core: params.row.code,
              };
              updateSubscriptionDetails(details);
              handleshowo();
            }}
            className="edit-button"
          >
            Edit
          </button>
          <button
            onClick={() => {
              const details = {
                username: params.row.username,
                password: params.row.password,
                subId: params.row.subscription_id,
                intSubId: params.row.internet_subscription_id,
                intPlanId: params.row.internet_plan_id,
                site: params.row.site,
                planName: params.row.plan_name,
                fullName: params.row.fullname_en,
                expDate: params.row.expiration_date,
                payDate: params.row.payment_date,
                consumption: params.row.consumption_option,
                connectivity: params.row.connectivity,
                due: params.row.due,
                ip: params.row.ip_address,
                notes: params.row.notes,
              };
              updateSubscriptionDetails(details);
              handleshowPing();
            }}
            className="edit-button"
          >
            Ping
          </button>
          <button
            onClick={() => {
              const details = {
                subId: params.row.subscription_id,
                intSubId: params.row.internet_subscription_id,
              };
              updateSubscriptionDetails(details);
              handleshowHistory();
            }}
            className="edit-button"
          >
            History
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.subscription_id;

  const handleCheckboxChange = (event, subscriptionId) => {
    const checked = event.target.checked;
    setCheckedRows((prevCheckedRows) => {
      if (checked) {
        return [...prevCheckedRows, subscriptionId];
      } else {
        return prevCheckedRows.filter((id) => id !== subscriptionId);
      }
    });
  };

  // Create a delayed search filter function
  const delaySearchFilter = (input) => {
    setSearchQuery(input);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchInput(query);

    // Clear the previous timeout if it exists
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }

    // Set a new timeout to trigger filtering after a delay
    const newTimeout = setTimeout(() => {
      delaySearchFilter(query);
    }, 100); // Adjust the delay as needed
    setSearchFilterTimeout(newTimeout);
  };

  const handleFilterChange = (field, value) => {
    // Update filters state
    setFilters(prevFilters => {
      const newFilters = {
        ...prevFilters,
        [field]: value
      };
  
      // Apply all active filters to get new filtered data
      const newFilteredData = data.filter(row => {
        // Apply modal filters
        const modalFilterMatch = Object.keys(newFilters).every(key => {
          if (!newFilters[key]) return true; // Skip empty filters
          const rowValue = String(row[key] || '').toLowerCase();
          const filterValue = String(newFilters[key]).toLowerCase();
          return rowValue.includes(filterValue);
        });
  
        // Apply search query filter
        const searchMatch = searchQuery.split(" ").every(word =>
          Object.values(row).some(value =>
            String(value).toLowerCase().includes(word.toLowerCase().trim())
          )
        );
  
        // Apply DataGrid filters
        const gridFilterMatch = gridFilterModel.items.every(filterItem => {
          if (!filterItem.value) return true;
          
          const cellValue = row[filterItem.field];
          const filterValue = filterItem.value;
  
          switch (filterItem.operator) {
            case 'contains':
              return String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase());
            case 'equals':
              return String(cellValue) === String(filterValue);
            case 'startsWith':
              return String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
            case 'endsWith':
              return String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase());
            default:
              return true;
          }
        });
  
        return searchMatch && modalFilterMatch && gridFilterMatch;
      });
  
      // Update filteredRowsData with new filtered results
      setFilteredRowsData(newFilteredData);
      
      return newFilters;
    });
  };

  // Combined filtering logic
  const filteredRows = data.filter(row => {
    // Apply search query filter
    const searchMatch = searchQuery.split(" ").every(word =>
      Object.values(row).some(value =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );

    // Apply modal filters
    const modalFilterMatch = Object.keys(filters).every(key => {
      if (!filters[key]) return true; // Skip empty filters
      const rowValue = String(row[key] || '').toLowerCase();
      const filterValue = String(filters[key]).toLowerCase();
      return rowValue.includes(filterValue);
    });

    // Apply DataGrid filters
    const gridFilterMatch = gridFilterModel.items.every(filterItem => {
      if (!filterItem.value) return true;
      
      const cellValue = row[filterItem.field];
      const filterValue = filterItem.value;

      switch (filterItem.operator) {
        case 'contains':
          return String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase());
        case 'equals':
          return String(cellValue) === String(filterValue);
        case 'startsWith':
          return String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
        case 'endsWith':
          return String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase());
        default:
          return true;
      }
    });

    // Return true only if all filters match
    return searchMatch && modalFilterMatch && gridFilterMatch;
  });

  const columnHeaders = {
    status: "Status",
    username: "Username",
    fullname_en: "Full Name",
    suffix: "Suffix",
    plan_name: "Plan Name",
    ip_address: "IP Address",
    site: "Site/Core",
    site_name: "Site Name",
    name: "Access Point",
    code: "Core",
    subscription_secret: "Token",
    connectivity: "Connectivity",
    consumption_option: "Option",
    due: "Due",
    mac_address: "Mac Address",
    expiration_date: "Expiry",
  };

  // Function to transform the rows based on columnHeaders
  const transformRowsForExport = (rows) => {
    return rows.map((row) => {
      const transformedRow = {};
      Object.keys(columnHeaders).forEach((key) => {
        transformedRow[columnHeaders[key]] = row[key];
      });
      return transformedRow;
    });
  };

  // Function to export to Excel
  const handleExportToExcel = () => {
    // Transform the rows to match the new column headers
    const transformedRows =
      filteredRowsData.length > 0
        ? transformRowsForExport(filteredRowsData)
        : transformRowsForExport(filteredRows);

    const worksheet = XLSX.utils.json_to_sheet(transformedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscription");
    XLSX.writeFile(workbook, "Subscription.xlsx");
  };
  const [isPressed, setIsPressed] = useState(false);

  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    // Optional: Reset the button state after a short delay
    setTimeout(() => {
      setIsPressed(false);
    }, 200); // Adjust the timeout as needed
  };
  
  const handleDeselectCheckboxes = () => {
    setCheckedRows([]);
  };
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
          fontSize: "14px",
          border: "none",
          backgroundColor: isPressed ? "white" : "transparent", // Change background on press
          color: isPressed ? "black" : "#007bff", // Change text color on press
          cursor: "pointer",
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: "20px",
            marginRight: "8px",
            filter: isPressed ? "invert(0)" : "invert(1)", // Make the SVG white or retain its original color
          }}
        />
        Excel
      </Button>
      <Button
        onClick={() => setShowFilterModal(true)}
        variant="outline-primary"
      >
        Filter
      </Button>
    </GridToolbarContainer>
  );

  return (
    <div className="InternetTable ">
      <div className="actions">
        <CustomizedActions
          subscriptions={checkedRows}
          deselect={handleDeselectCheckboxes}
        />
        <button
          className="btn btn-link nav-link"
          onClick={handleshowTransfer}
          style={{ textDecoration: "none" }} // Optional: remove the underline for the button
        >
          TransferDays
        </button>
        <input
          type="text"
          placeholder="Search..."
          value={searchInput}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            // Set the default page size to 10
            rowsPerPage={10} // Set the default number of rows per page to 10
            pagination
            disableSelectionOnClick
            filterModel={gridFilterModel}
            onFilterModelChange={(newModel) => setGridFilterModel(newModel) || handleFilterChange}
          />
        </div>
      )}
      <Modal show={showo} onHide={handleedit} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Subscription</Modal.Title>
        </Modal.Header>
        <div className="Subform">
        <Formsa
        su={subscriptionDetails.subId}
        intsu={subscriptionDetails.intSubId}
        intpa={subscriptionDetails.intPlanId}
        usr={subscriptionDetails.username}
        full={subscriptionDetails.fullName}
        sit={subscriptionDetails.site}
        con={subscriptionDetails.connectivity}
        opti={subscriptionDetails.consumption}
        plana={subscriptionDetails.planName}
        exp={subscriptionDetails.expDate}
        pay={subscriptionDetails.payDate}
        pass={subscriptionDetails.password}
        due={subscriptionDetails.due}
        notes={subscriptionDetails.notes}
        site={subscriptionDetails.site_name}
        WAP={subscriptionDetails.WAP}
        core={subscriptionDetails.core}
        mac={subscriptionDetails.mac_address}
        closeModal={handleedit}
      />
        </div>
      </Modal>
      <Modal show={showPing} onHide={handleClosePing}>
        <Modal.Header closeButton>
          <Modal.Title>Ping</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <Ping ip={subscriptionDetails.ip} />
        </div>
      </Modal>
      <Modal show={showHistory} onHide={handleCloseHistory}>
        <Modal.Header closeButton>
          <Modal.Title>Consumpion</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <ConsumptionHistory sub={subscriptionDetails.subId} />
        </div>
      </Modal>
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {columns
            .filter((col) => col.field !== "actions")
            .map((col) => (
              <div key={col.field} className="filter">
                <label>{col.headerName}</label>
                <input
                  type="text"
                  placeholder={`Filter by ${col.headerName}`}
                  value={filters[col.field] || ""}
                  onChange={(e) =>
                    handleFilterChange(col.field, e.target.value)
                  }
                  className="filter-input"
                />
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFilterModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showTransfer}
        onHide={handleCloseTransfer}
        className="modal-xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Transfer Days</Modal.Title>
        </Modal.Header>
        <div className="Subform">
          <TransferDays />
        </div>
      </Modal>
    </div>
  );
}

export default SubInternet;
